import React, { FC, useCallback, useEffect, useState } from "react";
import {
  LoginButton,
  LoginButtonProps,
} from "../components/LoginButton/LoginButton";
import { useKeycloak } from "@react-keycloak/ssr";
import { KeycloakInstance } from "keycloak-js";
import { usePortalAuth } from "../providers/keycloak-provider";
import { usePortalProvider } from "../providers/portal-provider";

export const LoginButtonContainer: FC = () => {
  const { keycloak, initialized } = useKeycloak<KeycloakInstance>();
  const { login, logout } = usePortalAuth();
  const [loading, setLoading] = useState<boolean>(false);
  const { ssr, profile, setProfile } = usePortalProvider();

  useEffect(() => {
    setLoading(true);
    if (!initialized) {
      return;
    }

    setLoading(false);

    if (keycloak?.authenticated) {
      setLoading(true);
      keycloak?.loadUserInfo().then((profile) => {
        setProfile(profile);
        setLoading(false);
      });
    }
  }, [keycloak, initialized, setProfile]);

  const onLogin = useCallback(() => {
    if (ssr) {
      login();
      return;
    }
    keycloak?.login();
  }, [login, keycloak, ssr]);

  const onLogout = useCallback(() => {
    if (ssr) {
      logout();
      return;
    }

    keycloak?.logout();
  }, [logout, keycloak, ssr]);

  const onAccountManagementRequest = useCallback(
    () => keycloak?.accountManagement(),
    [keycloak]
  );

  const props: LoginButtonProps = {
    onLogin,
    onLogout,
    onAccountManagementRequest,
    profile,
    loading,
  };

  return <LoginButton {...props} />;
};
