import React, { FC } from "react";
import { Space } from "antd";
import {
  FacebookFilled,
  TwitterSquareFilled,
  InstagramFilled,
  YoutubeFilled,
} from "@ant-design/icons";
import { ExternalLink } from "../Link/ExternalLink";

export const TopBar: FC = () => (
  <div className="xvia-header-topbar">
    <div className="xvia-header-info">
      <Space size={"large"}>
        <ExternalLink link="http://www.transparencia.mt.gov.br">
          Transparência
        </ExternalLink>

        <ExternalLink link="https://www.legislacao.mt.gov.br/">
          Legislação
        </ExternalLink>

        <ExternalLink link="/app/catalog/orgao">
          Secretarias do Governo
        </ExternalLink>
      </Space>
    </div>
    <div className="xvia-header-quick-links">
      <span>
        <ExternalLink link="https://www.facebook.com/govmatogrosso/">
          <FacebookFilled />
        </ExternalLink>
      </span>

      <ExternalLink link="https://twitter.com/GovMatoGrosso">
        <TwitterSquareFilled />
      </ExternalLink>

      <ExternalLink link="https://www.instagram.com/govmatogrosso/">
        <InstagramFilled />
      </ExternalLink>

      <ExternalLink link="https://www.youtube.com/@secom_mt">
        <YoutubeFilled />
      </ExternalLink>
    </div>
  </div>
);
