import React, { FC, MouseEventHandler, useCallback, useMemo } from "react";
import { Menu, Dropdown } from "antd";
import classnames from "classnames";

export interface LoginButtonProps {
  onLogin: MouseEventHandler<HTMLButtonElement>;
  onLogout: MouseEventHandler<HTMLButtonElement>;
  onAccountManagementRequest: MouseEventHandler<HTMLButtonElement>;
  profile?: Record<string, unknown>;
  loading: boolean;
}

export const LoginButton: FC<LoginButtonProps> = ({
  onLogin,
  onLogout,
  onAccountManagementRequest,
  profile,
  loading,
}) => {
  const handleClick = useCallback(
    (e) => {
      switch (e.key) {
        case "user-account":
          onAccountManagementRequest(e);
          break;
        case "logout":
          onLogout(e);
          break;
      }
    },
    [onAccountManagementRequest, onLogout]
  );

  const menu = (
    <Menu onClick={handleClick}>
      <Menu.Item key="user-account">Meus dados</Menu.Item>
      <Menu.Item key="logout">Sair</Menu.Item>
    </Menu>
  );

  const name = useMemo(() => {
    if (!profile?.name) {
      return;
    }
    const parts = (profile.name as string).split(" ");
    if (parts.length > 1) {
      return `${parts[0]} ${parts[parts.length - 1]}`;
    }

    return profile.name;
  }, [profile?.name]);

  const content = (
    <div
      className={classnames({
        "xvia-header-btn": true,
        "xvia-header-btn__loading": !profile && loading,
        "xvia-header-btn__inactive": !!profile,
      })}
    >
      {!profile ? (
        <button onClick={loading ? undefined : onLogin}>Entrar</button>
      ) : (
        <div className="xvia-header-user-actions">
          <Dropdown overlay={menu} placement="bottomRight">
            <button>
              {name || ""} <i className="far fa-user" />
            </button>
          </Dropdown>
        </div>
      )}
    </div>
  );

  return content;
};
